import React from "react"
import { Link } from "gatsby"

//import { Layout } from '../components/Layout';

import Layout from "../components/Layout"
import Image from "../components/image"
import SEO from "../components/seo"

//import { HomeComp } from '../components/Home';

const IndexPage = () => (
  <Layout
    title='Finanka Tarjeta '
    subtitle='Tu pasión, tu proyecto de vida…'
    works=''
  >
    <SEO title="Finanka Tarjeta " />

    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
    <Link to="/tarjeta/">Tarjeta</Link> <br />

    {
      /*
        <HomeComp/>
      */
    }
    

  </Layout>
)

export default IndexPage
